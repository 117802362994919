<template>
  <div class="knowledge-base-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="客服来源" name="first">
        <div class="knowledge-list-tools">
          <el-form class="battery-form" :inline="true">
            <el-form-item>
              <el-button type="primary" @click="addHandle()">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="knowledge-table-container">
          <div class="knowledge-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="类型" prop="sn">
                <template slot-scope="scope">
                  {{ scope.row.typeName || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加人" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新人" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.updateBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.enabled === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >启用</el-button
                  >
                  <el-button
                    v-if="scope.row.enabled === 1"
                    @click="setHandle(scope.row)"
                    type="text"
                    >停用</el-button
                  >
                  <el-button @click="editHandle(scope.row)" type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="knowledge-table-page">
            <div class="knowledge-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="联系人类型" name="second">
        <div class="knowledge-list-tools">
          <el-form class="battery-form" :inline="true">
            <el-form-item>
              <el-button type="primary" @click="addHandle()">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="knowledge-table-container">
          <div class="knowledge-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="类型" prop="sn">
                <template slot-scope="scope">
                  {{ scope.row.typeName || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加人" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新人" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.updateBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.enabled === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >启用</el-button
                  >
                  <el-button
                    v-if="scope.row.enabled === 1"
                    @click="setHandle(scope.row)"
                    type="text"
                    >停用</el-button
                  >
                  <el-button @click="editHandle(scope.row)" type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="knowledge-table-page">
            <div class="knowledge-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="业务类型" name="fifth">
        <div class="knowledge-list-tools">
          <el-form class="battery-form" :inline="true">
            <el-form-item>
              <el-button type="primary" @click="addHandle()">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="knowledge-table-container">
          <div class="knowledge-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="类型" prop="sn">
                <template slot-scope="scope">
                  {{ scope.row.typeName || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加人" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新人" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.updateBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.enabled === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >启用</el-button
                  >
                  <el-button
                    v-if="scope.row.enabled === 1"
                    @click="setHandle(scope.row)"
                    type="text"
                    >停用</el-button
                  >
                  <el-button @click="editHandle(scope.row)" type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="knowledge-table-page">
            <div class="knowledge-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="问题类型" name="third">
        <div class="knowledge-list-tools">
          <el-form class="battery-form" :inline="true">
            <el-form-item>
              <el-button type="primary" @click="addHandle()">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="knowledge-table-container">
          <div class="knowledge-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="类型" prop="sn">
                <template slot-scope="scope">
                  {{ scope.row.typeName || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加人" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新人" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.updateBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.enabled === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >启用</el-button
                  >
                  <el-button
                    v-if="scope.row.enabled === 1"
                    @click="setHandle(scope.row)"
                    type="text"
                    >停用</el-button
                  >
                  <el-button @click="editHandle(scope.row)" type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="knowledge-table-page">
            <div class="knowledge-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="处理操作" name="fourth">
        <div class="knowledge-list-tools">
          <el-form class="battery-form" :inline="true">
            <el-form-item>
              <el-button type="primary" @click="addHandle()">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="knowledge-table-container">
          <div class="knowledge-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="类型" prop="sn">
                <template slot-scope="scope">
                  {{ scope.row.typeName || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="添加人" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="更新人" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.updateBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.enabled === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >启用</el-button
                  >
                  <el-button
                    v-if="scope.row.enabled === 1"
                    @click="setHandle(scope.row)"
                    type="text"
                    >停用</el-button
                  >
                  <el-button @click="editHandle(scope.row)" type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="knowledge-table-page">
            <div class="knowledge-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <AddModel v-if="addVisable" @refreshDataList="getData" ref="addmodel" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
import "./Index.less";
import moment from "moment";
import AddModel from "./components/AddModel";
export default {
  name: "KnowledgeBase",
  components: {
    AddModel,
  },
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      addVisable: false,
      formData: {
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        baseType: 1,
        organId: null,
      },
      activeName: "first",
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.getData();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name === "first") {
        this.formData.baseType = 1;
      } else if (tab.name === "second") {
        this.formData.baseType = 2;
      } else if (tab.name === "third") {
        this.formData.baseType = 3;
      } else if (tab.name === "fourth") {
        this.formData.baseType = 4;
      } else {
        this.formData.baseType = 5;
      }
      this.getData();
    },
    //获取列表数据
    getData() {
      this.loading = true;
      this.list = null;
      axios
        .post(`/api/knowledgeBase/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    setHandle(row) {
      let enabled = null;
      if (row.enabled === 1) {
        enabled = 0;
      } else {
        enabled = 1;
      }
      axios
        .post(`/api/knowledgeBase/enabled`, {id: row.id, enabled: enabled})
        .then((res) => {
          if (res) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 500,
            });
            this.getData();
          }
        })
        .catch(() => {
          return this.$message.error("操作失败");
        });
    },
    addHandle() {
      this.addVisable = true;
      let name = ''
      if(this.formData.baseType === 1) {
        name = '信息来源'
      } else if(this.formData.baseType === 2) {
        name = '联系人类型'
      } else if(this.formData.baseType === 3) {
        name = '问题类型'
      }else if(this.formData.baseType === 4) {
        name = '处理操作'
      } else {
        name = '业务类型'
      }
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = "新增" + name
        this.$refs.addmodel.dataForm.baseType = this.formData.baseType;
      });
    },
    editHandle(row, type) {
      this.addVisable = true;
      let name = ''
      if(this.formData.baseType === 1) {
        name = '信息来源'
      } else if(this.formData.baseType === 2) {
        name = '联系人类型'
      } else if(this.formData.baseType === 3) {
        name = '问题类型'
      }else if(this.formData.baseType === 4) {
        name = '处理操作'
      } else {
        name = '业务类型'
      }
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = "编辑" + name;
        this.$refs.addmodel.dataForm.baseType = this.formData.baseType;
        this.$refs.addmodel.dataForm.id = row.id;
      });
    },
    //重置
    resetFrom() {
      this.formData = {
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        baseType: this.formData.baseType,
        organId: this.formData.organId,
      };
      this.getData();
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    typeStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "物流费";
        } else if (value === 2) {
          return "维修费";
        } else if (value === 2) {
          return "配件费";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
};
</script>