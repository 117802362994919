<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    @close="closeHandle"
    :destroy-on-close="true"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item v-if="dataForm.baseType !== 5" label="类型名称" :label-width="'120px'" prop="typeName">
          <el-input
            v-model="dataForm.typeName"
            placeholder="类型名称"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>

        <el-form-item v-if="dataForm.baseType === 5" label="业务标签" :label-width="'120px'" prop="typeName">
          <el-input
            v-model="dataForm.typeName"
            placeholder="业务标签"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>

        <el-form-item
          label="是否派发工单"
          v-if="dataForm.baseType === 3"
          :label-width="'120px'"
          prop="createOrder"
        >
          <el-radio-group v-model="dataForm.createOrder">
            <el-radio :label="1">支持</el-radio>
            <el-radio :label="0">不支持</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="业务子标签"
          v-if="dataForm.baseType === 5"
          :label-width="'120px'"
          prop="type"
        >
          <div
            style="display: flex"
            v-for="(item, index) in dataForm.childTagList"
            :key="index"
          >
            <el-input
              v-model="item.name"
              placeholder="业务子标签"
              clearable
              :style="{ width: '315px' }"
            />
            <i
              class="el-icon-minus"
              style="padding: 10px 0; margin-left: 10px"
              v-if="dataForm.childTagList.length > 1"
              @click="delTagHandle(index)"
            ></i>
            <i
              style="padding: 10px 0; margin-left: 10px"
              class="el-icon-plus"
              type="primary"
              @click="addTagHandle"
            ></i>
          </div>
        </el-form-item>

        <el-form-item
          label="问题子类"
          v-if="dataForm.baseType === 3"
          :label-width="'120px'"
          prop="type"
        >
          <div
            style="display: flex"
            v-for="(item, index) in dataForm.childProblemList"
            :key="index"
          >
            <el-input
              v-model="item.name"
              placeholder="子问题名称"
              clearable
              :style="{ width: '315px' }"
            />
            <i
              class="el-icon-minus"
              style="padding: 10px 0; margin-left: 10px"
              v-if="dataForm.childProblemList.length > 1"
              @click="delHandle(index)"
            ></i>
            <i
              style="padding: 10px 0; margin-left: 10px"
              class="el-icon-plus"
              type="primary"
              @click="addHandle"
            ></i>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        id: null,
        baseType: null,
        typeName: null,
        organId: null,
        enabled: 1,
        childProblemList: [],
        createOrder: null,
        childTagList: []
      },
      visible: false,
    };
  },
  computed: {
    rules() {
      return {
        typeName: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
        createOrder: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        sn: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
        occurrenceTime: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
       
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.baseType === 3 && !this.dataForm.id) {
          this.dataForm.childProblemList.push({ name: null });
        }
        if(this.dataForm.baseType === 5  && !this.dataForm.id) {
          this.dataForm.childTagList.push({ name: null });
        }
        if (this.dataForm.id) {
          this.getDataInfo();
        }
      });
    },
    closeHandle() {
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          id: null,
          baseType: null,
          typeName: null,
          organId: null,
          enabled: 1,
          childProblemList: [],
          createOrder: null,
          childTagList: []
        }
      })  
    },
    delHandle(i) {
      this.dataForm.childProblemList.splice(i, 1);
    },
    addHandle() {
      this.dataForm.childProblemList.push({ name: null });
    },
    addTagHandle() {
      this.dataForm.childTagList.push({ name: null });
    },
    delTagHandle(i) {
      this.dataForm.childTagList.splice(i, 1);
    },
    getDataInfo() {
      axios
        .post(`/api/knowledgeBase/detail`, { id: this.dataForm.id })
        .then((res) => {
          this.dataForm = { ...res };
        });
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的手机号"));
      }
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/knowledgeBase/save`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>